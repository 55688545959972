<template>
  <div>
    <el-dialog
      title="评价"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div style="margin-bottom: 20px">
        <span style="float: left; margin-right: 10px">内容质量评价:</span
        ><el-rate v-model="value2" show-text> </el-rate>
      </div>
      <div>
        <span style="float: left; margin-right: 10px">服务评价:</span
        ><el-rate v-model="value1" show-text> </el-rate>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="pjf"
          size="mini"
          style="width: 60px; margin-right: 45%"
          >确认
        </el-button>
      </span>
    </el-dialog>
    <div class="head">我的账单({{ billlenth }})</div>
    <div style="padding-left:10px;">
      <span>订单来源：</span>
      <el-select v-model="value" @change="init" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.value"
          :value="item.type">
        </el-option>
      </el-select>
    </div>
    <el-table :data="tableData" class="tableHead" style="width: 100%; font-size: 10px">
      <el-table-column label="订单编号名称" width="200px">
        <template slot-scope="scope">
          <el-tooltip effect="light" :content="scope.row.orderNum">
            <span style="margin-left: 10px"
              >{{ scope.row.orderNum }}<br />{{ scope.row.ordername }}</span
            >
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="类型">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.classification }}</span>
        </template>
      </el-table-column>
      <el-table-column label="知识币">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.Price }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单来源">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ billType(scope.row.type) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单时间" width="150px">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.time }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="状态" width="80">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.state }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="评价" >
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="pjck(scope.row)"
            v-if="scope.row.pshow"
            :disabled="scope.row.type==4||scope.row.classification!='支出'"
            >未评价</el-button
          >
          <span v-if="scope.row.pdshow"
            ><el-rate v-model="scope.row.evaluate" disabled> </el-rate>
          </span>
        </template>
      </el-table-column>
    </el-table>

    <div class="pageStyle">
      <el-pagination
        background
        @current-change="pageUpdate"
        :page-size="10"
        layout="prev, pager, next"
        :total="billlenth">
      </el-pagination>
    </div>

    <!-- 在线充值/兑换知识币 -->
    <div class="recharge-money">
      <p id="recharge-title">在线充值/兑换知识币</p>
      <div class="recharge-contain">
        <font class="letter-spacing-2">充值金额：</font>
        <el-input
          v-model="rechargeNum"
          @input="proving"
          maxlength="3"
          placeholder="请输入充值金额(正整数)"
        >
        </el-input>
        <font class="letter-spacing-2">充值方式：</font>
        <div class="pay-way">
          <div
            v-bind:class="{ 'pay-way-checked': payWay }"
            class="pay-ways inline-block vtc-mid"
            @click="wayOfWeixin"
          >
            <i
              v-bind:class="{ 'icon-checked': payWay }"
              class="el-icon-check icon"
            ></i>
            <img class="vtc-mid" src="../../../assets/icon/weixin.png" /> 微信
          </div>
          <div
            class="pay-ways inline-block vtc-mid"
            v-bind:class="{ 'pay-way-checked': !payWay }"
            @click="wayOfAlipay"
          >
            <i
              class="el-icon-check icon"
              v-bind:class="{ 'icon-checked': !payWay }"
            ></i>
            <img class="vtc-mid" src="../../../assets/icon/alipay.png" /> 支付宝
          </div>
          <span class="vtc-mid pay-manual">(1元 = 1个知识币)</span>
        </div>
      </div>
      <p>
        积分兑换<span class="letter-spacing-2 pay-manual">
          (1000积分/1个知识币)</span
        >
      </p>
      <div style="text-align: center; margin-top: 15px">
        <el-button type="primary" @click="chargeImmed">立即充值</el-button>
        <el-button type="primary" @click="handleOpen2">积分兑换</el-button>
      </div>
      <!-- 积分兑换知识币弹窗 -->
      <el-dialog title="积分兑换" :visible.sync="dialogVisible2" width="30%">
        <div v-show="!rechargeTip">
          <!-- 积分不足1000不予兑换 -->
          <span style="margin-right: 10px"
            >你目前积分不足 <font color="#020202">1000</font> 不予兑换</span
          >
        </div>
        <div v-show="rechargeTip">
          <div style="margin-bottom: 20px">
            <span style="margin-right: 10px"
              >你目前共有 <font color="#020202">{{ score }}</font> 积分 可兑换
              <font color="#020202">{{ scoreIcon }}</font> 个知识币</span
            >
            <span style="display: block; margin-top: 15px"
              >积分 <font color="#020202">{{ score }} -> </font
              ><font color="#020202">{{ scored }}</font></span
            >
          </div>
          <div>
            <span style="margin-right: 10px">要立即兑换吗?</span>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="dialogVisible2 = false" size="mini"
            >取消
          </el-button>
          <el-button
            type="primary"
            size="mini"
            v-show="rechargeTip"
            @click="rechargeOfSocre"
            >立即兑换</el-button
          >
        </span>
      </el-dialog>
      <!-- 微信支付扫二维码弹窗 -->
      <el-dialog
        title="微信支付"
        :visible.sync="weixinDialog"
        :close-on-click-modal="false"
        width="30%"
      >
        <div>
          <div>
            <span style="margin-right: 10px">请扫描微信二维码以完成支付</span>
          </div>
        </div>
        <div style="margin-top: 15px; text-align: center">
          <img
            style="max-width: 150%; height: auto"
            :src="weixinDialogImg"
            alt=""
          />
          <span style="margin-top: 10px; display: block"
            >金额: {{ respMoney }} 元</span
          >
        </div>
      </el-dialog>
    </div>
    <div class="divform"></div>
  </div>
</template>

<script>
import { INSERT, QUERY, DELETE, UPDATE, QUERYED } from "@/services/dao.js";
import { showTimeFormat, Bringbill, getTimeFormat } from "@/js/common";
// import eventBus from '@/js/common/eventBus';
// import changeA from '../index';
import axios from "axios";
export default {
  data() {
    return {
      billlenth: 0,    //总条数
      page:1,   //当前页

      tableData: [],
      dialogVisible: false,
      pshow: true,
      pdshow: false,
      value2: 0,
      value1: 0,
      pjObj:null,
      // uid: 0,
      dialogVisible2: false, // 积分兑换弹窗
      rechargeTip: true, // 积分是否满1000?true:false
      score: 0, // 积分数
      scoreIcon: 0, // 可兑换的知识币数
      scored: 0, // 兑换后剩余积分
      Money: 0, // 原来的知识币数
      payWay: true, // 充值方式
      defaultWay: 0, // 默认充值方式是微信
      rechargeNum: "", // 充值金额
      tradeNo: "", // 订单号
      weixinDialogImg: "", // 微信支付后台返回支付二维码图片地址
      weixinDialog: false, // 微信支付后台返回支付二维码图片弹窗显隐属性
      alipayDialog: false, // 支付宝支付弹窗显隐属性
      alipayForm: "", // 支付宝返回的form表单
      respMoney: 0, // 接口返回的充值金额
      sd: false,

      options: [
        {
        value: '所有',
        type: ''
      },{
        value: '学术头条',
        type: '0'
      }, {
        value: '智能问答',
        type: '1'
      }, {
        value: '资源交易',
        type: '2'
      }, {
        value: '订阅',
        type: '3'
      }, {
        value: '知识加工',
        type: '4'
      }, 
      {
        value: '知识服务',
        type: '5'
      }
      ],
      value: ''
    };
  },
  created() {
    this.init();
  },

  methods: {

    //获取订单列表
    async init() {
      Number;
      let res = await QUERYED(
        "post",
        "",
        'ALLBill( where: {userid: {_eq: "' +
          this.$store.state.userinfo.id +
          '"}'+
          (this.value?',type:{_eq:'+this.value+'}':'')+
          '},limit: ' +
          10 +
          ",offset: " +
          (this.page-1)*10 +
          ") { id   orderid  orderNum time type  state  Price  evaluate ordername classification rsuser}"+
          " ALLBill_aggregate(where:{userid: {_eq: \"" +this.$store.state.userinfo.id + "\"}"+
            (this.value?',type:{_eq:'+this.value+'}':'')
          +"}) {aggregate {count}}"
      );
      for (let i = 0; i < res.data.ALLBill.length; i++) {
        res.data.ALLBill[i].time = showTimeFormat(
          res.data.ALLBill[i].time
          // Date.parse(res.data.ALLBill[i].time)
        );
        res.data.ALLBill[i].evaluate = Number(res.data.ALLBill[i].evaluate);
        switch (res.data.ALLBill[i].state) {
          case 0:
            res.data.ALLBill[i].state = "已支付 ";
            break;
          case 1:
            res.data.ALLBill[i].state = "待支付";
            break;
          case 2:
            res.data.ALLBill[i].state = "订单取消 ";
            break;
          case 3:
            res.data.ALLBill[i].state = "支付失败";
            break;
        }
        if (
          res.data.ALLBill[i].evaluate == "" ||
          res.data.ALLBill[i].evaluate == null ||
          res.data.ALLBill[i].evaluate == "-1" ||
          res.data.ALLBill[i].evaluate == undefined ||
          !res.data.ALLBill[i].evaluate
        ) {
          res.data.ALLBill[i].pshow = true;
          res.data.ALLBill[i].pdshow = false;
        } else {
          res.data.ALLBill[i].pshow = false;
          res.data.ALLBill[i].pdshow = true;
        }

        if (res.data.ALLBill[i].state == "0") {
          res.data.ALLBill[i].classification = "支出";
        }

        if (res.data.ALLBill[i].type == "1") {
          switch (res.data.ALLBill[i].classification) {
            case 0:
              res.data.ALLBill[i].classification = "收入";
              break;
            case 1:
              res.data.ALLBill[i].classification = "支出";
              break;
            case 2:
              res.data.ALLBill[i].classification = "充值 ";
              break;
          }
        } else {
          switch (res.data.ALLBill[i].classification) {
            case 0:
              res.data.ALLBill[i].classification = "收入";
              break;
            case 1:
              res.data.ALLBill[i].classification = "支出";
              break;
            case 2:
              res.data.ALLBill[i].classification = "充值 ";
              break;
          }
        }
      }

     
      // //console.log('res',res);
      // let res1 = await QUERYED(
      //   "post",
      //   "",
      //   'ALLBill( where: {rsuser: {_eq: "' +
      //     this.$store.state.userinfo.id +
      //     '"},type: {_eq: "' +
      //     0 +
      //     '"}},limit: ' +
      //     10 +
      //     ",offset: " +
      //     0 +
      //     ") { id   orderid  orderNum time   state  Price    evaluate ordername classification rsuser}"
      // );
      // for (let i = 0; i < res1.data.ALLBill.length; i++) {
      //   res1.data.ALLBill[i].time = showTimeFormat(
      //     res1.data.ALLBill[i].time
      //     // Date.parse(res1.data.ALLBill[i].time)
      //   );
      //   switch (res1.data.ALLBill[i].state) {
      //     case 0:
      //       res1.data.ALLBill[i].state = "已支付 ";
      //       break;
      //     case 1:
      //       res1.data.ALLBill[i].state = "待支付";
      //       break;
      //     case 2:
      //       res1.data.ALLBill[i].state = "订单取消 ";
      //       break;
      //     case 3:
      //       res1.data.ALLBill[i].state = "支付失败";
      //       break;
      //   }
      //   res1.data.ALLBill[i].evaluate = Number(res1.data.ALLBill[i].evaluate);
      //   if (
      //     res1.data.ALLBill[i].evaluate == "" ||
      //     res1.data.ALLBill[i].evaluate == null ||
      //     res1.data.ALLBill[i].evaluate == "-1" ||
      //     res1.data.ALLBill[i].evaluate == undefined
      //   ) {
      //     res1.data.ALLBill[i].pshow = true;
      //     res1.data.ALLBill[i].pdshow = false;
      //   } else {
      //     res1.data.ALLBill[i].pshow = false;
      //     res1.data.ALLBill[i].pdshow = true;
      //   }
      //   switch (res1.data.ALLBill[i].classification) {
      //     case 0:
      //       res1.data.ALLBill[i].classification = "支出";
      //       break;
      //     case 1:
      //       res1.data.ALLBill[i].classification = "支出";
      //       break;
      //     case 2:
      //       res1.data.ALLBill[i].classification = "充值 ";
      //       break;
      //   }
      // }
      // //console.log('res1',res1);
      // let res2 = await QUERYED(
      //   "post",
      //   "",
      //   'ALLBill( where: {rsuser: {_eq: "' +
      //     this.$store.state.userinfo.id +
      //     '"},type: {_eq: "' +
      //     2 +
      //     '"}},limit: ' +
      //     10 +
      //     ",offset: " +
      //     0 +
      //     ") { id   orderid  orderNum time   state  Price    evaluate ordername classification rsuser}"
      // );
      // for (let i = 0; i < res2.data.ALLBill.length; i++) {
      //   res2.data.ALLBill[i].time = showTimeFormat(
      //     res2.data.ALLBill[i].time
      //     // Date.parse(res2.data.ALLBill[i].time)
      //   );
      //   switch (res2.data.ALLBill[i].state) {
      //     case 0:
      //       res2.data.ALLBill[i].state = "已支付 ";
      //       break;
      //     case 1:
      //       res2.data.ALLBill[i].state = "待支付";
      //       break;
      //     case 2:
      //       res2.data.ALLBill[i].state = "订单取消 ";
      //       break;
      //     case 3:
      //       res2.data.ALLBill[i].state = "支付失败";
      //       break;
      //   }
      //   res2.data.ALLBill[i].evaluate = Number(res2.data.ALLBill[i].evaluate);
      //   if (
      //     res2.data.ALLBill[i].evaluate == "" ||
      //     res2.data.ALLBill[i].evaluate == null ||
      //     res2.data.ALLBill[i].evaluate == "-1" ||
      //     res2.data.ALLBill[i].evaluate == undefined
      //   ) {
      //     res2.data.ALLBill[i].pshow = true;
      //     res2.data.ALLBill[i].pdshow = false;
      //   } else {
      //     res2.data.ALLBill[i].pshow = false;
      //     res2.data.ALLBill[i].pdshow = true;
      //   }
      //   switch (res2.data.ALLBill[i].classification) {
      //     case 0:
      //       res2.data.ALLBill[i].classification = "支出";
      //       break;
      //     case 1:
      //       res2.data.ALLBill[i].classification = "支出";
      //       break;
      //     case 2:
      //       res2.data.ALLBill[i].classification = "充值 ";
      //       break;
      //   }
      // }
      // //console.log('res2',res2);
      // this.tableData = res.data.ALLBill.concat(
      //   res1.data.ALLBill.concat(res2.data.ALLBill)
      // );

      this.tableData = res.data.ALLBill
      this.billlenth = res.data.ALLBill_aggregate.aggregate.count

      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i].pd = true;
      }
      //console.log("999", this.tableData);
      /**
       * 追加 tableData数据 查询bill中该用户的订阅记录
       */
      if (this.$store.state.userinfo.id !== undefined) {
        let resp = await QUERYED(
          "post",
          "",
          "bill(where:{userid:{_eq:" +
            this.$store.state.userinfo.id +
            "},type:{_eq:" +
            3 +
            "}}) {id time state evaluate orderNum Price classification subsed_user_name}"
        );
        //console.log("341: ", resp);
        let temp = resp.data.bill;
        if (temp.length > 0) {
          for (let i = 0; i < temp.length; i++) {
            if (
              temp[i].evaluate == "" ||
              temp[i].evaluate == null ||
              temp[i].evaluate == "-1" ||
              temp[i].evaluate == undefined ||
              !temp[i].evaluate
            ) {
              temp[i].pshow = true;
              temp[i].pdshow = false;
            } else {
              temp[i].pshow = false;
              temp[i].pdshow = true;
            }
            // this.tableData.push({
            //   id: temp[i].id,
            //   orderNum: temp[i].orderNum,
            //   ordername: "订阅 " + temp[i].subsed_user_name,
            //   state: "已支付",
            //   Price: temp[i].Price,
            //   time: showTimeFormat(temp[i].time),
            //   classification: "支出",
            //   pd: false,
            // });
          }
        }


        /**
         * 被订阅收入知识币
         */
        // let resp2 = await QUERYED(
        //   "post",
        //   "",
        //   "SubscribeUserInfo(where:{subscribed_user_id:{_eq:" +
        //     this.$store.state.userinfo.id +
        //     "}}) {createTime subscribe_type subscribers_user_name}"
        // );
        // let temp2 = resp2.data.SubscribeUserInfo;
        // if (temp2.length > 0) {
        //   for (let i = 0; i < temp2.length; i++) {
        //     if (
        //       temp2[i].evaluate == "" ||
        //       temp2[i].evaluate == null ||
        //       temp2[i].evaluate == "-1" ||
        //       temp2[i].evaluate == undefined ||
        //       !temp2[i].evaluate
        //     ) {
        //       temp2[i].pshow = true;
        //       temp2[i].pdshow = false;
        //     } else {
        //       temp2[i].pshow = false;
        //       temp2[i].pdshow = true;
        //     }
        //     this.tableData.push({
        //       orderNum: Bringbill.call(this),
        //       ordername: "被 " + temp2[i].subscribers_user_name + " 订阅",
        //       state: "已支付",
        //       Price:
        //         temp2[i].subscribe_type.search("基本") > -1
        //           ? temp2[i].subscribe_type
        //               .split("基本费用 (")[1]
        //               .split(") +")[0]
        //           : temp2[i].subscribe_type
        //               .split("一次性付费 (")[1]
        //               .split(") +")[0],
        //       time: showTimeFormat(temp2[i].createTime),
        //       classification: "收入",
        //       pd: false,
        //     });
        //   }
        // }
      }

      this.tableData.sort((a, b) => Date.parse(b.time) - Date.parse(a.time));
    },

    //分页时
    pageUpdate(e){
      this.page = e
      this.init()
    },






    handleEdit(index, row) {
      // //console.log(index, row);
    },
    async handleDelete(index, row) {
      //console.log(index, row);
      let info = await DELETE(
        "post",
        "",
        "delete_ZYDocument_all(where: {id: {_eq: " +
          row.id +
          "}}) {     affected_rows   }"
      );
      //console.log(info.data.delete_ZYDocument_all.affected_rows);
      if (info.data.delete_ZYDocument_all.affected_rows > 0) {
        this.init();
        this.$message({
          message: "删除成功",
          type: "warning",
        });
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    // 输入充值金额验证
    proving() {
      this.rechargeNum = this.rechargeNum
        .replace(/[^\.\d]/g, "")
        .replace(/^0{1,}/g, "");
      this.rechargeNum = this.rechargeNum.replace(".", "");
      if (this.rechargeNum > 500) this.rechargeNum = 500;
    },
    // 立即充值
    async chargeImmed() {
      //console.log(this.defaultWay);
      //console.log(this.defaultWay);
      //console.log(typeof this.rechargeNum);
      parseFloat(this.rechargeNum);
      //console.log(typeof parseFloat(this.rechargeNum));
      if (this.rechargeNum != "") {
        this.tradeNo = Bringbill.call(this); // 订单号
        //console.log(this.tradeNo);
        // 做测试先不验证
        if (this.defaultWay == 0) {
          // 微信接口
          await axios
            .post(this.api.LoginURL.concat("/common/weChatPay/pay"), {
              // orderMoney: 0.01,
              orderMoney: this.rechargeNum,
              orderName: "知识币充值",
              WIDout_trade_no: this.tradeNo,
            })
            .then(async (resp) => {
              //console.log("resp: 微信： ", resp);
              if (resp.data.success) {
                // 返回二维码成功

                this.weixinDialog = true;
                this.weixinDialogImg = this.api.LoginURL.concat(
                  resp.data.data.path
                );
                this.respMoney = JSON.parse(resp.config.data).orderMoney;
                // 不管是否支付,插入该条记录
                let info = await INSERT(
                  "post",
                  "",
                  "insert_Recharge_no(objects:{user_id:" +
                    this.$store.state.userinfo.id +
                    ',bill_no:"' +
                    this.tradeNo +
                    '",createTime:"' +
                    new Date() +
                    '",recharge_money:' +
                    0.01 +
                    ",state:" +
                    0 +
                    "}) {affected_rows}"
                );
                //console.log("weixin_Info", info);
                if (info.data.insert_Recharge_no.affected_rows != 1) {
                  //console.log("记录失败！");
                  return "";
                }
                // 隔10秒发送请求查看state
                let delay = setInterval(async () => {
                  let info2 = await QUERYED(
                    "post",
                    "",
                    'Recharge_no(where:{bill_no:{_eq:"' +
                      this.tradeNo +
                      '"}}) {state}'
                  );
                  //console.log("回调：", info2);
                  if (info2.data.Recharge_no[0].state == 1) {
                    clearInterval(delay);

                    let info3 = await QUERYED(
                      "post",
                      "",
                      "PCUsers(where: {id: {_eq: " +
                        this.$store.state.userinfo.id +
                        "}}) { integral Money  }"
                    );
                    //console.log("info3: ", info3);
                    // 更新积分 知识币
                    this.$root.Hub.$emit(
                      "oneEvent",
                      info3.data.PCUsers[0].integral,
                      info3.data.PCUsers[0].Money
                    );
                    this.weixinDialog = false;
                    this.$message.success("支付成功!");
                  }
                }, 10000);
              } else {
                // 返回二维码失败
                this.$message.warning("服务器出错,稍后再试~");
              }
            })
            .catch((error) => {
              //console.log(error);
            });
        } else {
          // 支付宝接口
          await axios
            .post(this.api.LoginURL.concat("/common/aliPay/pay"), {
              // total_amount: 0.01,
              total_amount: this.rechargeNum,
              subject: "知识币充值",
              out_trade_no: this.tradeNo,
              body: "知识币充值",
            })
            .then(async (resp) => {
              //console.log("resp:支付宝： ", resp);
              if (resp.data.success) {
                // 支付接口成功!

                // 打开支付页面
                document.querySelector("body").innerHTML =
                  resp.data.data.result; //查找到当前页面的body，将后台返回的form替换掉他的内容
                document.forms[0].submit(); //执行submit表单提交，让页面重定向，跳转到支付宝页面

                // 不管是否支付,插入该条记录
                let info = await INSERT(
                  "post",
                  "",
                  "insert_Recharge_no(objects:{user_id:" +
                    this.$store.state.userinfo.id +
                    ',bill_no:"' +
                    this.tradeNo +
                    '",createTime:"' +
                    new Date() +
                    '",recharge_money:' +
                    0.01 +
                    ",state:" +
                    0 +
                    "}) {affected_rows}"
                );
                //console.log("weixin_Info", info);
                if (info.data.insert_Recharge_no.affected_rows != 1) {
                  //console.log("记录失败！");
                }

                // 隔10秒发送请求查看state
                let delay = setInterval(async () => {
                  let info2 = await QUERYED(
                    "post",
                    "",
                    'Recharge_no(where:{bill_no:{_eq:"' +
                      this.tradeNo +
                      '"}}) {state}'
                  );
                  //console.log("回调：", info2);
                  if (info2.data.Recharge_no[0].state == 1) {
                    clearInterval(delay);
                    let info3 = await QUERYED(
                      "post",
                      "",
                      "PCUsers(where: {id: {_eq: " +
                        this.$store.state.userinfo.id +
                        "}}) { integral Money  }"
                    );
                    //console.log("info3: ", info3);
                    // 更新积分 知识币
                    this.$root.Hub.$emit(
                      "oneEvent",
                      info3.data.PCUsers[0].integral,
                      info3.data.PCUsers[0].Money
                    );
                    this.$message.success("支付成功!");
                  }
                }, 10000);
              } else {
                // 支付接口调用失败
                this.$message.warning("服务器出错,稍后再试~");
              }
            })
            .catch((error) => {
              //console.log(error);
            });
        }
      } else {
        this.$message.warning("请输入充值金额!");
      }
    },
    // 打开积分兑换弹窗
    async handleOpen2() {
      // 查询数据库该用户积分

      let resp = await QUERYED(
        "post",
        "",
        "PCUsers(where:{id:{_eq:" +
          this.$store.state.userinfo.id +
          "}}){integral Money}"
      );
      //console.log(resp);
      if (resp.success) {
        if (resp.data.PCUsers[0].integral >= 1000) {
          this.score = resp.data.PCUsers[0].integral;
          this.scoreIcon = Math.floor(resp.data.PCUsers[0].integral / 1000);
          this.scored = resp.data.PCUsers[0].integral % 1000;
          this.Money = parseFloat(resp.data.PCUsers[0].Money);
          this.rechargeTip = true;
        } else {
          this.rechargeTip = false;
        }
      } else {
        this.$message.warning("服务器错误 稍后再试~");
        this.dialogVisible2 = false;
      }
      this.dialogVisible2 = true;
    },
    // 点击立即兑换更新数据库 积分 知识币 数据
    async rechargeOfSocre() {
      // 增加知识币
      this.Money += this.scoreIcon;
      // //console.log(typeof this.Money)
      //console.log(this.Money);
      let resp = await UPDATE(
        "post",
        "",
        "update_User(where:{id:{_eq:" +
          this.$store.state.userinfo.id +
          "}},_set:{integral:" +
          this.scored +
          ",Money:" +
          this.Money +
          "}){affected_rows}"
      );

      //console.log("response：", resp);
      if (resp.success) {
        this.dialogVisible2 = false;
        this.$message.success("兑换成功!");
        // 刷新Personal Center/index.vue 积分和知识币的信息
        this.$root.Hub.$emit("oneEvent", this.scored, this.Money); // 触发主页面视图更新
      } else {
        this.$message.warning("服务器错误 稍后再试~");
        this.dialogVisible2 = false;
      }
    },
    // 支付方式--微信被选中
    wayOfWeixin() {
      this.defaultWay = 0;
      this.payWay = true;
      //console.log(this.defaultWay);
    },
    // 支付方式--支付宝被选中
    wayOfAlipay() {
      this.defaultWay = 1;
      this.payWay = false;
      //console.log(this.defaultWay);
    },
    pjck(item) {
      console.log(item,123456879);
      if(item.type==4)return;
      this.dialogVisible = true;
      // this.uid = item.id;
      this.pjObj = item
    },
    async pjf() {
      let {id,orderid,type} = this.pjObj
      let user = ''    //用户或团队id
      let person = 0   //0：个人    1：团队
      if(type==1){
        // '智能问答'
        let WdAnswerRef = await QUERYED(
          "post",
          "",
          "WdAnswerRef(where:{question:{_eq:"+orderid+"},adopt:{_eq:1}}){user person team}"
        );
        if(WdAnswerRef.success&&WdAnswerRef.data.WdAnswerRef[0]){
          person = WdAnswerRef.data.WdAnswerRef[0].person
          if(person==0){
            user = WdAnswerRef.data.WdAnswerRef[0].user
          }else{
            user = WdAnswerRef.data.WdAnswerRef[0].team
          }
        }else{
          this.$message.warning("评价失败，请稍后再试");
          return
        }
      }else if(type==2||type==3){
        // '资源交易'
        let ZYDocument_all = await QUERYED(
          "post",
          "",
          "ZYDocument_all(where:{id:{_eq:"+orderid+"}}){user}"
        );
        if(ZYDocument_all.success&&ZYDocument_all.data.ZYDocument_all[0]){
          user = ZYDocument_all.data.ZYDocument_all[0].user
        }else{
          this.$message.warning("评价失败，请稍后再试");
          return
        }
      }else if(type==5){
        // '知识服务'
        return
      }else{
        // '其他'
        return
      }
      
      let data = await UPDATE(
        "POST",
        "",
        'update_bill(_set:{evaluate: "' +
          this.value2 +
          '"}, where: {id: {_eq:' +
          id +
          "}}) {  affected_rows }"
      );

      if (data.data.update_bill.affected_rows > 0) {
        this.$message({
          message: "评价成功",
          type: "success",
          duration: "1000",
        });
        this.dialogVisible = false;
        this.pjObj = null
        this.value2 = 0;
        this.value1 = 0;
        this.init();
        if(this.value2>=5){
          if(person==0){
            let creditRating = await QUERYED(
              "post",
              "",
              "User(where:{id:{_eq:"+user+"}}){creditRating}"
            );
            let num = creditRating.data.User[0].creditRating+1
            let aa = await UPDATE(
              "POST",
              "",
              'update_User(_set:{creditRating:'+num+'},where:{id:{_eq:'+user+"}}){affected_rows}"
            );
          }else{
            let WdInvitation = await QUERYED(
              "post", 
              "",
              "WdInvitation(where:{questioner:{_eq:"+orderid+"},team:{_eq:"+user+"}}){user}"
            );
            if(WdInvitation.success){
              for(let item of WdInvitation.data.WdInvitation){
                let creditRating = await QUERYED(
                  "post",
                  "",
                  "User(where:{id:{_eq:"+item.user+"}}){creditRating}"
                );
                let num = creditRating.data.User[0].creditRating+1
                let aa = await UPDATE(
                  "POST",
                  "",
                  'update_User(_set:{creditRating:'+num+'},where:{id:{_eq:'+item.user+"}}){affected_rows}"
                );
              }
            }else{
              this.$message.warning("评价失败，请稍后再试");
            }
          }
        }
      }
    },

    //订单类型
    billType(type){
      switch (type) {
        case 0:return '学术头条';
        case 1:return '智能问答';
        case 2:return '资源交易';
        case 3:return '订阅';
        case 4:return '知识加工';
        case 5:return '知识服务';
        default:return '其他类型';
          break;
      }
    }

  },
};
</script>
<style scoped>
.head {
  line-height: 50px;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 800;
}
/* .tableHead>>>.el-table__body{
  width: 100% !important;
} */
.el-table {
  border: 1px solid #d2d2d2;
  margin: 10px;
}
.el-table__body-wrapper {
  overflow: hidden;
  height: 400px !important;
  overflow-y: scroll;
  position: relative;
}
.el-rate {
  line-height: 1.3 !important;
}
/* 在线充值/兑换知识币 */
.recharge-money {
  padding-left: 10px;
  margin-top: 40px;
}
#recharge-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: #030303;
}
.letter-spacing-2 {
  letter-spacing: 2px;
}
.recharge-contain {
  padding: 20px;
  border: 1px solid #ebeef5;
}
.pay-way {
  margin-top: 8px;
  cursor: default;
}
.pay-ways {
  margin-right: 30px;
  border: 1px solid #ebeef5;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
}
.pay-way div img {
  margin-right: 10px;
  max-width: 24px;
  height: auto;
}
.inline-block {
  display: inline-block;
}
.vtc-mid {
  vertical-align: middle;
}
.pay-manual {
  color: #c0c4cc;
}
.pay-way-checked {
  border: 1px solid #66a7fd;
  margin-right: 30px;
  padding: 10px 15px 10px 15px;
  position: relative;
}
.icon {
  position: absolute;
  display: none;
}
.icon-checked {
  position: absolute;
  display: block;
  top: -1px;
  right: 2px;
}

/* reset-style */
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 100%;
  margin-top: 7px;
  margin-bottom: 7px;
}
.el-icon-check:before {
  content: "\e6da";
  font-size: 13px;
  color: #16e3bb;
}
.pageStyle{
  display: flex;
  justify-content: center;
}
</style>
